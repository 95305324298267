import {
  booleanAttribute,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import { TenderTopic } from '../../models/tender-topic';
import { TreeItem, TreeviewItem } from '../../../lib/ngx-treeview';
import { Period, TendersSearchService } from '../../services/tenders-search.service';
import { buildTenderTerritoriesTreeItems } from '../../services/helpers/tender-territory';
import { DbTender } from '../../models/tender';
import { TerritoryKind } from '../../../core/territory-kind/territory-kind.enum';
import { TranslateService } from '@ngx-translate/core';
import { DbTenderTerritory } from '../../models/tender-territory';
import { MarketTypesEnum, TenderTypesEnum } from '../../models/tender-search-body';
import { TendersModuleService } from '../../services/tenders-module.service';
import { FilterItem } from "../../../common-explain/components/filter-text-and-items/filter-text-and-items.component";
import { FilteringPeriod } from "../tenders-search/tenders-search.component";
import { BrowserDetectorService } from "../../../common-explain/services/browser-detector.service";

export enum FilterType {
  TOPIC = 'topic',
  TERRITORY = 'territory',
  PERIOD = 'period',
  TENDER_TYPE = 'tender-type',
  MARKET_TYPE = 'market-type',
}

@Component({
  selector: 'app-tenders-filter',
  templateUrl: './tenders-filter.component.html',
  styleUrls: ['./tenders-filter.component.scss'],
})
export class TendersFilterComponent implements OnInit, OnChanges {
  protected readonly FilterType = FilterType;

  @Input({required: true}) tenderSearchService!: TendersSearchService;
  @Input() filters?: FilterType[]
  @Input() defaultPeriod?: FilteringPeriod;
  @Input({transform: booleanAttribute}) inTenderSearchView = false;

  @Output() filterChange = new EventEmitter<{
    event: 'territories';
    value: string[];
  } | {
    event: 'tender-types';
    value: TenderTypesEnum[];
  } | {
    event: 'market-types';
    value: MarketTypesEnum[];
  } | {
    event: 'period';
    value: Period;
  }>();

  tenderTopics?: TenderTopic[];
  selectableItems?: {id: number, name: string, description?: string}[];
  defaultTopicIds?: number[];
  defaultTerritoriesIds?: string[];
  defaultTenderTypes?: TenderTypesEnum[];
  defaultMarketTypes?: MarketTypesEnum[];
  territoryItems!: TreeviewItem;
  treeItems: TreeItem[] = new Array<TreeItem>();
  tenders = new Array<DbTender>();
  totalCount = 0;
  territorySelectionCount = 0;
  alternativeDisplay = '';
  userDataLoaded = false;
  searchText: string = '';
  show = false;

  constructor(private tendersModuleService: TendersModuleService,
              private translate: TranslateService,
              protected browser: BrowserDetectorService) {}

  async ngOnInit() {
    this.tenderTopics = await this.tendersModuleService.tenderTopics;
    this.selectableItems = this.tenderTopics?.map((topic) => ({id: topic.id, name: topic.name, description: topic.description}));
    await this.buildAllRegionsTreeItems();
    this.userDataLoaded = true;
    this.updateFilters();
  }

  onTextSearch() {
    this.searchText = this.searchText.trim(); // sanitizing spaces at the end of the word
    if (!this.searchText) return;
    this.tenderSearchService.searchText = this.searchText;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tenderSearchService'] && this.userDataLoaded) {
      this.updateFilters();
    }
  }

  async buildAllRegionsTreeItems() {
    // On cherche à récupérer l'ensemble du territoire français.
    // Cette méthode est résiliente aux erreurs de récupération des territoires, et au fait que l'utilisateur n'ait pas tous les départements.
    const territories: DbTenderTerritory[] = await this.tendersModuleService.userRegions;

    // gestion du cas où les territoires ne sont pas récupérés.
    this.alternativeDisplay = territories.length ? '' : 'tenders.error-retrieving-territories';

    this.totalCount = territories.reduce((acc, cur) => acc + (cur.territories?.length ?? 0), 0);
    // construction des éléments de l'arbre des territoires.
    this.treeItems = territories.map((territory) =>
      buildTenderTerritoriesTreeItems(territory)
    );
    // tri des elements
    this.treeItems.sort((a, b) => {
      if (['DROM','COM'].includes(a.text)) return 1;
      return a.text.localeCompare(b.text)
    }); // tri alpha des regions
    this.treeItems.forEach((ti) =>
      ti.children?.sort((a, b) => a.text.localeCompare(b.text)) // tri alpha des départements
    );
    // Création de l'arbre des territoires.
    this.territoryItems = new TreeviewItem({
        checked: false,
        collapsed: false,
        value: null,
        text: this.translate.instant("tenders.territory-filter-all"),
        children: this.treeItems,
        isRoot: true
      }
    );
  }

  topicsSelected(event: number[] | null) {
    this.tenderSearchService.selectedTopics = event;
    this.defaultTopicIds = event ?? undefined;
  }

  periodSelected(event: { period: Period | undefined, field: "indexation_period" | "estimated_end_period" }) {
    if (!event.period) return;
    if (event.field === 'indexation_period') {
      this.tenderSearchService.selectedIndexationPeriod = event.period;
    } else if (event.field === 'estimated_end_period') {
      this.tenderSearchService.selectedEstimatedEndPeriod = event.period;
    }
  }

  territoriesSelected(event: string[]) {
    if (!event.length) {
      event = this.territoryItems.getSelection().uncheckedItems.filter((elm) =>
        !elm.value.includes(TerritoryKind.REGION)).map((item) => item.value);
      this.defaultTerritoriesIds = [];
    } else this.defaultTerritoriesIds = event;
    this.tenderSearchService.selectedTerritories = event;
    this.filterChange.emit({event: 'territories', value: event})

  }

  tenderTypesSelected(event: TenderTypesEnum[]) {
    if (!event.length) {
      event = Object.values(TenderTypesEnum);
      this.defaultTenderTypes = [];
    } else this.defaultTenderTypes = event;
    this.tenderSearchService.tenderTypes = event;
    this.filterChange.emit({event: 'tender-types', value: event})
  }

  marketTypesSelected(event: MarketTypesEnum[]) {
    if (!event.length) {
      event = Object.values(MarketTypesEnum);
      this.defaultMarketTypes = [];
    } else this.defaultMarketTypes = event;
    this.tenderSearchService.marketTypes = event;
    this.filterChange.emit({event: 'market-types', value: event})
  }

  updateFilters() {
    // TERRITORIES
    this.defaultTerritoriesIds = this.tenderSearchService.selectedTerritories?.length === this.totalCount ?
      [] : this.tenderSearchService.selectedTerritories;
    if (!this.tenderSearchService.selectedTerritories) {
      // Selection de tous les territoires par défaut.
      this.territoryItems.children.forEach(child => child.setCheckedRecursive(false));
      this.territorySelectionCount = this.territoryItems.getSelection().uncheckedItems.filter((elm) =>
        !elm.value.includes(TerritoryKind.REGION)).length;
      this.tenderSearchService.selectedTerritories =
        this.territoryItems.getSelection().uncheckedItems.filter((elm) =>
          !elm.value.includes(TerritoryKind.REGION)).map((item) => item.value);
    }

    // TOPICS
    this.defaultTopicIds = this.tenderSearchService.selectedTopics ?? [];

    // TENDER TYPES
    this.defaultTenderTypes = this.tenderSearchService.tenderTypes?.length === Object.values(TenderTypesEnum).length ?
      [] : this.tenderSearchService.tenderTypes;
    if (!this.tenderSearchService.tenderTypes) {
      this.tenderSearchService.tenderTypes = Object.values(TenderTypesEnum) as TenderTypesEnum[];
    }

    // MARKET TYPES
    this.defaultMarketTypes = this.tenderSearchService.marketTypes?.length === Object.values(MarketTypesEnum).length ?
      [] : this.tenderSearchService.marketTypes;
    if (!this.tenderSearchService.marketTypes) {
      this.tenderSearchService.marketTypes = Object.values(MarketTypesEnum) as MarketTypesEnum[];
    }
  }

  textSelected(text: string | null) {
    this.searchText = text?.trim() ?? '';
    this.onTextSearch();
  }

  topicSelected(item: FilterItem | null) {
    if(item) this.topicsSelected([item.id]);
  }
}
