import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TenderTypesEnum } from '../../models/tender-search-body';

@Component({
  selector: 'app-filter-tender-type',
  templateUrl: './filter-tender-type.component.html',
  styleUrls: ['./filter-tender-type.component.scss']
})
export class FilterTenderTypeComponent implements OnChanges {
  @Input() defaultTypes?: TenderTypesEnum[];

  tenderTypes: { name: string; value: TenderTypesEnum; checked: boolean }[];
  @Output() typesSelected = new EventEmitter<TenderTypesEnum[]>();

  constructor() {
    this.tenderTypes = (Object.keys(TenderTypesEnum) as Array<keyof typeof TenderTypesEnum>).map(key => ({
        name: `tenders.tender-type.${key.toLowerCase()}`,
        value: TenderTypesEnum[key],
        checked: false
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultTypes']) {
      this.tenderTypes.forEach(t => t.checked = this.defaultTypes?.includes(t.value) ?? false);
    }
  }

  onFilterChange() {
    this.typesSelected.emit(this.tenderTypes
        .filter(t => t.checked)
        .map(t => t.value)
    )
  }

  getSelectionCount() {
    return this.tenderTypes.filter(t => t.checked).length;
  }

  getTotalCount() {
    return this.tenderTypes.length;
  }
}
