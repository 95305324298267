<section
  [hidden]="tenderId"
  id="ms-container"
>
  <!--Header part-->
  <div id="ms-header">
    <div id="tender-search-header">
      <div id="tender-search-title">
        {{ "tenders.search-title" | translate}}
      </div>
      <div class="search-header-container d-flex flex-column py-2 px-4">
        <div class="d-flex">
          <app-tenders-filter inTenderSearchView [tenderSearchService]="tenderSearchService"
                              [filters]="[FilterType.PERIOD]"
                              [defaultPeriod]="defaultPeriod">
          </app-tenders-filter>
          <div class="flex-center-center"><div class="flex-center-center vertical-line"></div></div>
          <div class="d-flex flex-center-center toggle-filters text-truncate no-select pdl-8px" (click)="showFilters = !showFilters">
            {{ (showFilters ? 'tenders.board.less-filters' : 'tenders.board.more-filters') | translate }}
          </div>
        </div>
        <div class="tenders-filters" [hidden]="!showFilters">
          <app-tenders-filter
            [tenderSearchService]="tenderSearchService"
            [filters]="[FilterType.TERRITORY, FilterType.TENDER_TYPE, FilterType.MARKET_TYPE]"
          />
        </div>
      </div>
    </div>
    <div *ngIf="tenders?.length || tenderSearchService.refinedSearchText"
         class="result-header-bar d-flex">
      <app-tenders-summary [tendersSearchService]="tenderSearchService"/>
      <div class="sort-container ms-auto">
        <ex-filter-text [placeholder]="'tenders.filter-results' | translate"
                        [text]="tenderSearchService.refinedSearchText"
                        (textChange)="onRefineResults($event)"/>
        <app-tenders-sort [tendersSearchService]="tenderSearchService" defaultSort="_score" />
        <app-tenders-action-toggle [disabled]="!tenderSearchService.tenders?.length" />
      </div>
    </div>
  </div>

  <app-tenders-list [tendersSearchService]="tenderSearchService" [tendersExportService]="tendersExportService"
                    [ngClass]="{'background-image' : tenders === undefined || tenders?.length === 0 && !tenderSearchService.refinedSearchText}"
  />
  <app-tenders-action-bar [tendersSearchService]="tenderSearchService"/>
</section>

<router-outlet>
</router-outlet>
