<ex-dropdown placeholder="Sélectionnez un type"
             strictWidth
             noInput
             size="small"
>
    <ng-template exDropdownDisplay>
        <div class="selection-display" [ngClass]="{'selection-placeholder': !getSelectionCount()}">
            <span>{{ 'tenders.filter.market-type' | translate }}</span>
            <span class="selection-display-count">
                ({{ (getSelectionCount() ? 'tenders.filter.selected' : 'tenders.filter.all')
                | translate: {count: getSelectionCount(), total: getTotalCount()} }})
            </span>
        </div>
    </ng-template>
    <ng-template exDropdownContent>
        <div class="market-types">
            <div *ngFor="let item of marketTypes" class="form-check">
                <label class="align-self-center checkbox">
                    <input type="checkbox"
                           class="form-check-input"
                           [checked]="item.checked"
                           (change)="item.checked = !item.checked; onFilterChange()"
                    >
                    <span class="form-check-label">{{item.name | translate}}</span>
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </ng-template>
</ex-dropdown>
