import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MarketTypesEnum } from '../../models/tender-search-body';

@Component({
  selector: 'app-filter-market-type',
  templateUrl: './filter-market-type.component.html',
  styleUrls: ['./filter-market-type.component.scss']
})
export class FilterMarketTypeComponent implements OnChanges {
  @Input() defaultTypes?: MarketTypesEnum[];

  marketTypes: { name: string; value: MarketTypesEnum; checked: boolean }[];
  @Output() typesSelected = new EventEmitter<MarketTypesEnum[]>();

  constructor() {
    this.marketTypes = (Object.keys(MarketTypesEnum) as Array<keyof typeof MarketTypesEnum>).map(key => ({
        name: `tenders.market-type.${key.toLowerCase()}`,
        value: MarketTypesEnum[key],
        checked: false
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultTypes']) {
      this.marketTypes.forEach(t => t.checked = this.defaultTypes?.includes(t.value) ?? false);
    }
  }

  onFilterChange() {
    this.typesSelected.emit(this.marketTypes
        .filter(t => t.checked)
        .map(t => t.value)
    )
  }

  getSelectionCount() {
    return this.marketTypes.filter(t => t.checked).length;
  }

  getTotalCount() {
    return this.marketTypes.length;
  }
}
