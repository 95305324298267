import { DbTenderTerritoryLight, TenderTerritoryLight } from './tender-territory';
import { MarketTypesEnum, TenderTypesEnum } from './tender-search-body';
import { getDateWithoutTime, hasNoTime } from '../../shared/helpers/date-helper';

export enum NotAllowedMarketTypes {
  AUTRES = 'autres',
  MAITRISES_D_OEUVRES = 'maîtrises d\'oeuvres',
}


export interface DbTender {
  id: string;
  title: string;
  tender_type: string;
  inquirers: string[];
  indexation_date: string;
  closing_date?: string;                      // may be null from api
  territories: DbTenderTerritoryLight[];      // may be equal to []
  source?: string;
  market_type?: string[];
  url?: string;
  dce_url?: string;
  dce_project_uid: string;
  is_suggestion?: boolean;
  is_relevant?: boolean;
  cluster_id?: number[];
  score?: number;
  tender_annotations_id?: number;
  status_id?: number;
  note?: string;
  assigned_user_id?: number;
  estimated_end_date?: string;
  assignees?: string[];
  amount?: { min: number, max: number, total: number };
}

export class Tender {
  id: string;
  title: string;
  tenderType: string;
  inquirers: string[];
  indexationDate: Date;
  closingDate?: Date;
  territories: TenderTerritoryLight[];
  source?: string;
  marketType?: string[];
  url?: string;
  dceUrl?: string;
  dceProjectUid: string;
  daysLeft?: number;
  isSuggestion?: boolean;
  isRelevant?: boolean;
  cluster_id?: number[];
  score?: number;
  tenderAnnotationsId?: number;
  statusId?: number;
  note?: string;
  assignedUserId?: number;
  estimatedEndDate?: Date;
  assignees?: string[];
  amount?: { min: number, max: number, total: number };

  hide?: boolean;
  watchAppearance?: MarketWatchAppearance | null;

  constructor(dbTender: DbTender) {
    this.id = dbTender.id;
    this.title = dbTender.title;
    this.tenderType = dbTender.tender_type ?? 'undetermined';
    this.inquirers = dbTender.inquirers;
    this.indexationDate = new Date(dbTender.indexation_date);
    this.closingDate = dbTender.closing_date ? new Date(dbTender.closing_date) : undefined;
    this.source = dbTender.source;
    this.territories = dbTender.territories
      .map((dbTTL: DbTenderTerritoryLight) => new TenderTerritoryLight(dbTTL))
      .sort((a, b) => a.code.localeCompare(b.code));
    this.marketType = this.mapMarketType(dbTender.market_type);
    this.url = dbTender.url;
    this.dceProjectUid = dbTender.dce_project_uid;
    this.daysLeft = this.calculateDaysLeft();
    this.isSuggestion = dbTender.is_suggestion ?? false;
    this.isRelevant = dbTender.is_relevant;
    this.cluster_id = dbTender.cluster_id;
    this.score = dbTender.score;
    this.tenderAnnotationsId = dbTender.tender_annotations_id;
    this.statusId = dbTender.status_id;
    this.note = dbTender?.note;
    this.assignedUserId = dbTender.assigned_user_id;
    this.dceUrl = dbTender.dce_url;
    this.estimatedEndDate = dbTender.estimated_end_date ? new Date(dbTender.estimated_end_date) : undefined;
    this.assignees = dbTender.assignees;
    this.amount = dbTender.amount;
  }

  mapMarketType(marketType: string[] | undefined): string[] {
    // This method enables to change what market type is gonna be displayed :
    // - the "autres" market type as if there was no market type
    // - the "maîtrises d'oeuvres" market type as if it was both "Travaux" and "Services"
    return Array.from(new Set(
      marketType?.filter(type => type !== NotAllowedMarketTypes.AUTRES)
      .flatMap(type =>
        type === NotAllowedMarketTypes.MAITRISES_D_OEUVRES ? [MarketTypesEnum.TRAVAUX, MarketTypesEnum.SERVICES] : [type]
      )
    )).sort((a, b) => a.localeCompare(b)) ?? [];
  }

  calculateDaysLeft() {
    if (this.tenderType === TenderTypesEnum.ATTRIBUTION) return -1; // STOP si attribution
    let toReturn = undefined;
    const today = new Date();
    const closingDate = this.closingDate ? new Date(this.closingDate) : undefined;

    if (this.isSameDay(today, closingDate)) {
      if (closingDate && hasNoTime(closingDate)) {
        toReturn = 0;
      } else if (closingDate && today.getTime() > closingDate.getTime()) {
        toReturn = -1;
      } else {
        return 0;
      }
    } else {
      if (closingDate) toReturn = Math.floor((getDateWithoutTime(closingDate) - getDateWithoutTime(today)) / (1000 * 60 * 60 * 24));
      if (toReturn && toReturn < 0) toReturn = -1;
    }
    return toReturn;
  }

  isSameDay(date1: Date, date2: Date | undefined) {
    return date2 && (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

}

export interface MarketWatchAppearance {
  id: number;
  market_watch_date: string; // "2024-09-27",
  market_watch_id: number;
  market_watch_name: string;
  market_watch_url: string; // "https://staging-app.explain.fr/tenders/watch?id=10106&to_date=2024-09-27"
}
